import { NextSeo } from 'next-seo';

import Layout from '../components/Layout';
import config from '../utils/config';
import Hero from '../components/home/Hero';
import WorkFlow from '../components/home/WorkFlow';
import Features from '../components/home/Features';
import About from '../components/home/About';
import Contact from '../components/home/Contact';

function Index() {
  return (
    <Layout>
      <NextSeo
        title={config.siteName}
        description="A short description goes here."
      />
      <Hero />
      <WorkFlow />
      <Features />
      <About />
      <Contact />
    </Layout>
  );
}

export default Index;
