import styled from 'styled-components';
import config from '../../utils/config';
import { Heading, Paragraph } from '../atoms';

const UnderLine = styled.div`
  border-bottom-width: 7px;
`;

const FeatureItem = ({ isReverse, data }) => {
  return (
    <div
      className={`md:flex md:gap-24 gap-6 items-center px-4 ${
        isReverse && 'flex-row-reverse'
      }`}
    >
      <div className="md:w-5/12">
        <img src={data.image} alt={config.siteName} />
      </div>
      <div className="md:w-1/2 md:pt-0 pt-8">
        <img src={data.icon} alt={data.title} className="h-3/5" />
        <Heading className="text-textDarkLite text-3xl font-semibold font-tertiaryFontFamily pt-2">
          {data.title}
        </Heading>
        <UnderLine className="border-secondaryLite w-40 -mt-1" />
        <Paragraph className="text-base text-textDark mb-8 pt-4 font-quaternaryFontFamily">
          {data.description}
        </Paragraph>
      </div>
    </div>
  );
};

export default FeatureItem;
