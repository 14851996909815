import { Heading, Paragraph } from '../atoms';

const AboutItem = ({ item }) => (
  <div className="flex mt-4">
    <img src={item.img} alt={item.title} className="h-5 w-5 mt-1" />
    <div className="ml-3">
      <Heading className="font-tertiaryFontFamily font-semibold">
        {item.title}
      </Heading>
      <Paragraph
        size="base"
        className="font-quaternaryFontFamily text-textDark"
      >
        {item.description}
      </Paragraph>
    </div>
  </div>
);

const aboutFeatures = [
  {
    id: 1,
    img: '/icons/fire.svg',
    title: 'Impact.',
    description:
      'Making something from nothing and seeing work come to life is what drives us, we want to bring businesses to the forefront of their industry and exceed expectations.',
  },
  {
    id: 2,
    img: '/icons/app.svg',
    title: 'Art and science.',
    description:
      'The power in blending customer insight and data analytics is what will help us create great products.',
  },
  {
    id: 3,
    img: '/icons/hand-sm.svg',
    title: 'Partnership.',
    description:
      'With each other and the businesses we work with, we take a long term view and keep in focus the joint bigger picture goals.',
  },
  {
    id: 4,
    img: '/icons/lightening.svg',
    title: 'Fire in the belly.',
    description: 'With everything we do we want to do it with energy.',
  },
];

const About = () => (
  <div
    className="flex md:gap-16 gap-6 items-center px-4 py-20 flex-col-reverse lg:flex-row relative"
    id="about"
  >
    <div className="xl:w-1/2 md:w-full lg:w-1/2 md:pl-8 lg:pl-48 pl-0 2xl:pl-60">
      <Heading
        type="h2"
        className="text-3xl font-medium font-tertiaryFontFamily"
      >
        About
      </Heading>
      <Paragraph
        size="base"
        className="py-4 font-quaternaryFontFamily text-textDark"
      >
        The Fore is a startup that enables entrepreneurs to grow their business
        through creating digital experiences. We partner with people who are
        experts in their field and allow them to go to the next level with tech
        we have built. The Fore believes in:
      </Paragraph>
      {aboutFeatures.map((item) => (
        <AboutItem key={item.id} item={item} />
      ))}
    </div>
    <div className="absolute hidden lg:flex bottom-16">
      <img src="/icons/dumbell.svg" alt="dumbell" className="h-20 w-20" />
    </div>
    <div className=" md:w-full lg:w-1/2">
      <img src="/images/dash.gif" alt="trainer-dashboard" className="w-full" />
    </div>
  </div>
);

export default About;
