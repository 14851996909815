/* eslint-disable no-return-assign */
import React, { useRef } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';

import FeatureItem from './FeatureItem';

const Container = styled.div`
  .slick-dots {
    li button:before,
    li.slick-active button:before {
      color: transparent;
      opacity: 1;
    }
    li button:before {
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background: #c4c4c4;
    }
    li.slick-active button:before {
      background-color: #80ffc6;
      color: transparent;
    }
  }
  .slick-dots li {
    margin: 0px;
  }
`;

const SliderWrapper = styled.div`
  button {
    cursor: pointer;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 2.5rem;
    align-items: center;
    display: flex;
  }
`;

const features = [
  {
    id: 1,
    icon: '/icons/feature-icon.svg',
    image: '/images/feature1.png',
    title: 'Customised Branding',
    description:
      ' Your own app listing in Apple’s App Store and the Google Play store, will be customised to reflect and extend your brand. Your clients buy in to you and an app powered by The Fore is designed to grow this.',
  },
  {
    id: 2,
    icon: '/icons/feature-icon.svg',
    image: '/images/feature2.png',
    title: 'Live and pre recorded workouts',
    description:
      ' We allow you to deliver class style content through either a live or prerecorded video. Deliver live streaming workouts at set times and then make them available for your clients who weren’t able to attend. Or simply uploaded a workout you’ve done previously.',
  },
  {
    id: 3,
    icon: '/icons/feature-icon.svg',
    image: '/images/feature3.png',
    title: 'Gym workout plans, tracking and tutorials',
    description:
      'Give your clients everything they need to maximise their time in the gym. Help them understand correct form through video content and tips. Reps or timed based exercises are supported as well as a rest timer to follow.',
  },
  {
    id: 4,
    icon: '/icons/feature-icon.svg',
    image: '/images/feature4.png',
    title: 'Nutrition',
    description:
      ' Inspire your clients to make healthier choices in reaching their goals through a range of meal based recipes. Break each meal or snack down by macros, ingredients and recipes.',
  },
];

const AppFeatures = () => {
  const customSlider = useRef();
  const settings = {
    dots: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <Container className="mt-24 py-16 bg-lite pb-16" id="features">
      <div className="container mx-auto">
        <div className="flex lg:w-1/2 md:w-2/3 2xl:w-1/3 mx-auto text-center">
          <div className="w-full">
            <h1 className="md:text-5xl text-3xl py-8 font-medium md:mb-4 mb-8 font-tertiaryFontFamily">
              Features
            </h1>
          </div>
        </div>
        <div className="flex items-center">
          <SliderWrapper className="w-2/12 justify-center hidden md:flex">
            <button
              type="button"
              className="border-textLite border-2"
              onClick={() => customSlider.current.slickPrev()}
            >
              <BiChevronLeft className="h-10 w-10 text-textLite" />
            </button>
          </SliderWrapper>
          <div className="md:w-10/12 w-full">
            <Slider
              {...settings}
              ref={(slider) => (customSlider.current = slider)}
            >
              {features.map((item) => {
                return <FeatureItem key={item.id} data={item} />;
              })}
            </Slider>
          </div>
          <SliderWrapper className="w-2/12 justify-center hidden md:flex">
            <button
              type="button"
              className="border-textLite border-2"
              onClick={() => customSlider.current.slickNext()}
            >
              <BiChevronRight className="h-10 w-10 text-textLite" />
            </button>
          </SliderWrapper>
        </div>
      </div>
    </Container>
  );
};

export default AppFeatures;
