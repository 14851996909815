import styled from 'styled-components';

import { Heading, Paragraph } from '../atoms';

const HeadingStyled = styled(Heading)`
  min-height: 4rem;
  @media only screen and (max-width: 480px) {
    min-height: 0rem;
  }
`;

const workFlowSteps = [
  {
    id: 1,
    icon: '/icons/hand.svg',
    title: 'Tell us about your business',
    subtitle: 'Get in contact below to start a conversation',
  },
  {
    id: 2,
    icon: '/icons/mobile.svg',
    title: 'Design your bespoke app',
    subtitle:
      'We work with you to build an app that is an extension of the business you are building',
  },
  {
    id: 3,
    icon: '/icons/content.svg',
    title: 'Enter content',
    subtitle:
      'You then use our CMS to upload workouts, nutrition and plan your live schedule',
  },
  {
    id: 4,
    icon: '/icons/launch.svg',
    title: 'Launch',
    subtitle: 'You get your own iOS and Android app!',
  },
];
const WorkFlow = () => {
  return (
    <div className="container mx-auto">
      <div className="sm:pt-28 md:pt-20 lg:pt-32 pt-24 px-3">
        <div className="flex lg:w-1/2 md:w-2/3 2xl:w-1/3 mx-auto text-center mb-12">
          <div className="w-full">
            <h1 className="md:text-5xl text-3xl md:mb-8 font-medium text-textDarkLite font-tertiaryFontFamily">
              How it works
            </h1>
          </div>
        </div>
        <div className="max-w-lg mx-auto grid gap-6 lg:grid-cols-4 lg:max-w-none">
          {workFlowSteps.map((item) => (
            <div key={item.id} className="text-center">
              <img src={item.icon} alt={item.title} className="h-10 mx-auto" />
              <HeadingStyled className="text-textDarkLite my-4 text-2xl font-semibold w-2/3 mx-auto text-center font-tertiaryFontFamily">
                {item.title}
              </HeadingStyled>
              <div>
                <Paragraph className="text-textDark leading-normal font-quaternaryFontFamily">
                  {item.subtitle}
                </Paragraph>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WorkFlow;
