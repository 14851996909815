import { useEffect } from 'react';
import styled from 'styled-components';
import { gql, useMutation } from '@apollo/client';
import Swal from 'sweetalert2';

import { Heading, Paragraph } from '../atoms';
import HomeContactFrom from '../forms/HomeContactFrom';

const Container = styled.div`
  background-image: url('../images/contact-bg.png');
`;

const mutation = gql`
  mutation contact($input: ContactInput!) {
    contact(input: $input) {
      success
    }
  }
`;

const Contact = () => {
  const [execute, { data, error }] = useMutation(mutation);
  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: 'error',
        title: error.message,
      });
    }
    if (data && data.contact) {
      Swal.fire({
        icon: 'success',
        title: 'Message sent!',
      });
    }
  }, [error, data]);

  return (
    <Container
      className="py-20 px-4 bg-no-repeat bg-cover relative"
      id="contact"
    >
      <div className="absolute -top-8 md:left-48">
        <img src="/icons/lock.svg" alt="lock" className="w-16" />
      </div>
      <div className="max-w-screen-lg mx-auto">
        <Heading className="text-white text-5xl text-center font-medium font-tertiaryFontFamily">
          Contact Us
        </Heading>
        <Paragraph className="text-white text-center font-quaternaryFontFamily pt-4 pb-8">
          Help us get to know you and evaluate if there is a fit.
        </Paragraph>
        <div className="container mx-auto">
          <HomeContactFrom
            onSubmit={async (values) => {
              await execute({
                variables: {
                  input: values,
                },
              });
            }}
          />
        </div>
      </div>
    </Container>
  );
};

export default Contact;
