import React, { useRef } from 'react';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';

import TextInputGroup from '../elements/TextInputGroup';
import TextAreaGroup from '../elements/TextAreaGroup';
import { Error, Label } from '../atoms';
import ButtonSecondary from '../atoms/ButtonSecondary';

const formId = 'HomeContactForm';

const HomeContactForm = ({
  values,
  touched,
  errors,
  isSubmitting,
  handleSubmit,
  handleChange,
  handleBlur,
  setFieldValue,
}) => {
  const recaptchaRef = useRef();
  return (
    <form className="flex flex-wrap -m-2" onSubmit={handleSubmit} id={formId}>
      <div className="p-2 md:px-5 md:w-1/2 w-full">
        <TextInputGroup
          hasNotBorder
          labelClass="font-quaternaryFontFamily"
          borderRadius="rounded-lg"
          textColor="text-white"
          label="Name"
          name="name"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.name && touched.name ? errors.name : undefined}
        />
      </div>
      <div className="p-2 md:px-5 md:w-1/2 w-full">
        <TextInputGroup
          hasNotBorder
          labelClass="font-quaternaryFontFamily"
          borderRadius="rounded-lg"
          textColor="text-white"
          label="Email"
          name="email"
          type="email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.email && touched.email ? errors.email : undefined}
        />
      </div>
      <div className="p-2 md:px-5 md:w-1/2 w-full">
        <TextInputGroup
          hasNotBorder
          labelClass="font-quaternaryFontFamily"
          borderRadius="rounded-lg"
          textColor="text-white"
          label="Instagram"
          name="instagram"
          type="instagram"
          value={values.instagram}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            errors.instagram && touched.instagram ? errors.instagram : undefined
          }
        />
      </div>
      <div className="p-2 md:px-5 md:w-1/2 w-full">
        <TextInputGroup
          hasNotBorder
          labelClass="font-quaternaryFontFamily"
          borderRadius="rounded-lg"
          textColor="text-white"
          label="Website"
          name="website"
          type="website"
          value={values.website}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.website && touched.website ? errors.website : undefined}
        />
      </div>
      <div className="p-2 md:px-5 md:w-1/2 w-full">
        <TextAreaGroup
          hasNotBorder
          labelClass="font-quaternaryFontFamily"
          borderRadius="rounded-lg"
          textColor="text-white"
          label="What is current status of your online coaching business?"
          name="message"
          type="message"
          value={values.message}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.message && touched.message ? errors.message : undefined}
        />
      </div>
      <div className="p-2 md:px-5 md:w-1/2 w-full">
        <TextAreaGroup
          hasNotBorder
          labelClass="font-quaternaryFontFamily"
          borderRadius="rounded-lg"
          textColor="text-white"
          label="What goals do you have for your business?"
          name="business"
          type="business"
          value={values.business}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            errors.business && touched.business ? errors.business : undefined
          }
        />
      </div>
      <div className="p-2 md:px-5 md:w-1/2 w-full">
        <TextAreaGroup
          hasNotBorder
          labelClass="font-quaternaryFontFamily"
          borderRadius="rounded-lg"
          textColor="text-white"
          label="How do you see an app helping you reach your goals?"
          name="feedback"
          type="feedback"
          value={values.feedback}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            errors.feedback && touched.feedback ? errors.feedback : undefined
          }
        />
      </div>
      {/* <div className="p-2 md:px-5 md:w-1/2 w-full">
        <Label textColor="text-white font-quaternaryFontFamily">Captcha</Label>
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey="Your client site key"
          onChange={(response) => {
            setFieldValue('recaptcha', response);
          }}
        />
        {errors.recaptcha && touched.recaptcha && (
          <Error>{errors.recaptcha}</Error>
        )}
      </div> */}
      <div className="w-full md:ml-6">
        <ButtonSecondary
          type="submit"
          className="px-14 mt-4 md:ml-0 ml-2"
          form={formId}
          isLoading={isSubmitting}
          size="medium"
        >
          Send
        </ButtonSecondary>
      </div>
    </form>
  );
};

export default withFormik({
  mapPropsToValues: () => ({
    name: '',
    email: '',
    instagram: '',
    website: '',
    business: '',
    message: '',
    feedback: '',
    // recaptcha: '',
  }),
  validationSchema: Yup.object().shape({
    name: Yup.string().required('Name is required!'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required!'),
    website: Yup.string().required('This field is required!'),
    business: Yup.string().required('This field is required!'),
    message: Yup.string().required('This field is required!'),
    feedback: Yup.string().required('This field is required!'),
    // recaptcha: Yup.string().required('recaptcha is required!'),
  }),

  handleSubmit: (values, { setSubmitting, resetForm, props }) => {
    // console.log('handleSubmit', values);
    props.onSubmit(values).finally(() => {
      setSubmitting(false);
      resetForm();
    });
  },
  displayName: formId, // helps with React DevTools
})(HomeContactForm);
