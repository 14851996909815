// import { useRouter } from 'next/router';
import styled from 'styled-components';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import { Paragraph } from '../atoms';
import config from '../../utils/config';
import ButtonSecondary from '../atoms/ButtonSecondary';

const Container = styled.div`
  background-image: url('../images/bg1.png');
  background-position: bottom;
  /* @media only screen and (min-width: 1536px) {
    background-size: 100% 100%;
  } */
`;

const TextBorder = styled.span`
  border-bottom-width: 7px;
`;

const Hero = () => {
  return (
    <Container className="md:pt-4 bg-top bg-no-repeat bg-cover">
      <div className="md:min-h-[50vh] px-4">
        <div className="container mx-auto">
          <div className="text-white mx-auto md:pt-4 pt-10 text-center font-semibold">
            <div className="z-50 pt-8">
              <h1 className="text-2xl md:text-3xl xl:text-4xl text-center font-normal font-tertiaryFontFamily">
                Take your online coaching business to the{' '}
                <TextBorder className="border-secondaryLite">
                  next level
                </TextBorder>
              </h1>
              <Paragraph
                size="base"
                fontWeight="normal"
                className="md:mt-10 md:mb-12 mt-12 mb-6 max-w-md mx-auto font-quaternaryFontFamily"
              >
                Get your own customised iOS and Android app to scale your online
                coaching business.
              </Paragraph>
              <AnchorLink href="#contact">
                <ButtonSecondary
                  isActive
                  size="medium"
                  className="px-8 text-white  font-normal font-quaternaryFontFamily"
                >
                  Contact Us
                </ButtonSecondary>
              </AnchorLink>
            </div>
          </div>
          <div className="absolute hidden md:flex">
            <img src="/icons/dumbell-pair.svg" alt="dumbell pair" />
          </div>
          <div className="mx-auto w-full md:-mt-08 mt-4 -z-50">
            <img
              src="/images/hero-mockup.png"
              alt={`${config.siteName} dashboard`}
              layout="responsive"
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Hero;
