import styled from 'styled-components';
import { Header, Footer } from './global';

const Container = styled.div.attrs({ className: 'mx-auto' })``;

const Layout = ({ children, hasNoHeader, hasNoFooter }) => (
  <Container>
    {!hasNoHeader && <Header />}
    {children}
    {!hasNoFooter && <Footer />}
  </Container>
);

export default Layout;
